var MIXPANEL_EVENTS = {
  OPEN_PATIENT_CARD: "OPEN_PATIENT_CARD",
  PATIENT_MERGE: "PATIENT_MERGE",
  MESSAGE_SENT: "MESSAGE_SENT",
  SUBMIT_ADD_PATIENT: "SUBMIT_ADD_PATIENT",
  SUBMIT_EDIT_PATIENT: "SUBMIT_EDIT_PATIENT",
  OPEN_ADD_PATIENT: "OPEN_ADD_PATIENT",
  OPEN_EDIT_PATIENT: "OPEN_EDIT_PATIENT",
  FILE_UPLOAD: "FILE_UPLOAD",
  FORM_SUBMITTED: "FORM_SUBMITTED",
  FORM_OPENED: "FORM_OPENED",
  FORM_CLOSED: "FORM_CLOSED",
  FORM_SELECTOR_OPENED: "FORM_SELECTOR_OPENED",
  AUDIO_UPLOAD: "AUDIO_UPLOAD",
  ACTIVATE_CODE_STROKE: "ACTIVATE_CODE_STROKE",
  DEACTIVATE_CODE_STROKE: "DEACTIVATE_CODE_STROKE",
  UPDATE_CODE_STROKE: "UPDATE_CODE_STROKE",
  LKW_ADDED: "LKW_ADDED",
  LKW_CALCULATOR_OPEN: "LKW_CALCULATOR_OPEN",
  LKW_CALCULATOR_SAVED: "LKW_CALCULATOR_SAVED",
  PATIENT_SHARE_OPEN: "PATIENT_SHARE_OPEN",
  PATIENT_SHARED: "PATIENT_SHARED",
  REPORT_UPDATED: "REPORT_UPDATED",
  VIEWER_TOOLBAR_SELECTED: "VIEWER_TOOLBAR_SELECTED",
  STRUCTURED_RESPONSE_CALL_PATIENT: "STRUCTURED_RESPONSE_CALL_PATIENT",
  STRUCTURED_RESPONSE_CLICK: "STRUCTURED_RESPONSE_CLICK",
  SERIES_CLICKED: "SERIES_CLICKED",
  SERIES_LOADED: "SERIES_LOADED",
  SERIES_UNLOADED: "SERIES_UNLOADED",
  SIDEBAR_SERIES_CLICKED: "SIDEBAR_SERIES_CLICKED",
  FILTER_APPLIED: "FILTER_APPLIED",
  FILTER_SAVED: "FILTER_SAVED",
  FILTER_UPDATED: "FILTER_UPDATED",
  FILTER_DELETED: "FILTER_DELETED",
  PATIENT_SEARCH: "PATIENT_SEARCH",
  FULL_WIDTH_WORKLIST_OPENED: "FULL_WIDTH_WORKLIST_OPENED",
  FULL_WIDTH_WORKLIST_CLOSED: "FULL_WIDTH_WORKLIST_CLOSED",
  OPEN_SETTINGS: "OPEN_SETTINGS",
  UPDATE_NOTIFICATION_SETTINGS: "UPDATE_NOTIFICATION_SETTINGS",
  UPDATE_WORKLIST_TAGS_SETTINGS: "UPDATE_WORKLIST_TAGS_SETTINGS",
  UPDATE_FORM_SETTINGS: "UPDATE_FORM_SETTINGS",
  UPDATE_AUTOMATED_SHARING_SETTINGS: "UPDATE_AUTOMATED_SHARING_SETTINGS",
  DELETE_AUTOMATED_SHARING_SETTINGS: "DELETE_AUTOMATED_SHARING_SETTINGS",
  CREATE_USER_GROUP: "CREATE_USER_GROUP",
  UPDATE_USER_GROUP: "UPDATE_USER_GROUP",
  LEAVE_USER_GROUP: "LEAVE_USER_GROUP",
  DELETE_USER_GROUP: "DELETE_USER_GROUP",
  ADD_USER_TO_GROUP: "ADD_USER_TO_GROUP",
  REMOVE_USER_FROM_GROUP: "REMOVE_USER_FROM_GROUP",
  UPDATE_WORKSPACE_MANAGEMENT_SETTINGS: "UPDATE_WORKSPACE_MANAGEMENT_SETTINGS",
  ADD_WORKSPACE_MANAGEMENT_SETTINGS: "ADD_WORKSPACE_MANAGEMENT_SETTINGS",
  MAKE_WORKSPACE_DEFAULT: "MAKE_WORKSPACE_DEFAULT",
  SHOW_HIDE_WORKSPACE_PATIENTS: "SHOW_HIDE_WORKSPACE_PATIENTS",
  EXIT_WORKSPACE: "EXIT_WORKSPACE",
  UPDATE_REPORT_TEMPLATES_SETTINGS: "UPDATE_REPORT_TEMPLATES_SETTINGS",
  ADD_REPORT_TEMPLATES_SETTINGS: "ADD_REPORT_TEMPLATES_SETTINGS",
  DELETE_REPORT_TEMPLATES_SETTINGS: "DELETE_REPORT_TEMPLATES_SETTINGS",
  OPEN_REPORT_TEMPLATE_SIGNATURE: "OPEN_REPORT_TEMPLATE_SIGNATURE",
  OPEN_CREATE_REPORT_TEMPLATE: "OPEN_CREATE_REPORT_TEMPLATE",
  OPEN_EDIT_REPORT_TEMPLATE: "OPEN_EDIT_REPORT_TEMPLATE",
  UPDATE_FORMATTING_SETTINGS: "UPDATE_FORMATTING_SETTINGS",
  EXPORT_DATA_SETTINGS: "EXPORT_DATA_SETTINGS",
  EXPORT_NEW_DATA_SETTINGS: "EXPORT_NEW_DATA_SETTINGS",
  OPEN_MERGE_PATIENT: "OPEN_MERGE_PATIENT",
  ADD_SCANS_FOR_PATIENT: "ADD_SCANS_FOR_PATIENT",
  OPEN_TERMS_OF_USE: "OPEN_TERMS_OF_USE",
  USER_LOGGEDOUT: "USER_LOGGEDOUT",
  OTP_LOGIN_SUCCESS: "OTP_LOGIN_SUCCESS",
  DASHBOARD_OPENED: "DASHBOARD_OPENED",
  DASHBOARD_LOADED: "DASHBOARD_LOADED",
  DASHBOARD_FILTERED: "DASHBOARD_FILTERED",
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  SELECT_SETTING: "SELECT_SETTING",
  GATEWAY_POC_UPDATE: "GATEWAY_POC_UPDATE",
  THEME_CHANGED: "THEME_CHANGED",
  OPEN_ADD_PHONE_NUMBER: "OPEN_ADD_PHONE_NUMBER",
  OPEN_UPDATE_PHONE_NUMBER: "OPEN_UPDATE_PHONE_NUMBER",
  SAVE_PHONE_NUMBER: "SAVE_PHONE_NUMBER",
  MESSAGE_CLICKED: "MESSAGE_CLICKED",
  VIEWER_HEADER_NAVIGATION: "VIEWER_HEADER_NAVIGATION",
  ENABLE_NOTIFICATION_FROM_NUDGE: "ENABLE_NOTIFICATION_FROM_NUDGE",
  IGNORE_NOTIFICATION_FROM_NUDGE: "IGNORE_NOTIFICATION_FROM_NUDGE",
  UPDATE_SYSTEM_NOTIFICATION_SETTINGS: "UPDATE_SYSTEM_NOTIFICATION_SETTINGS",
  FORM_RESET: "FORM_RESET",
  FORM_RESET_CANCEL: "FORM_RESET_CANCEL",
  UNSAVED_SETTINGS_DISCARD: "UNSAVED_SETTINGS_DISCARD",
  UNSAVED_SETTINGS_CANCEL: "UNSAVED_SETTINGS_CANCEL",
  SHOW_SYNC_DETAILS: "SHOW_SYNC_DETAILS",
  CLOSE_SYNC_DETAILS: "CLOSE_SYNC_DETAILS",
  SEARCH_PATIENT_FOR_MERGE: "SEARCH_PATIENT_FOR_MERGE",
  CLEAR_SEARCH_PATIENT_FOR_MERGE: "CLEAR_SEARCH_PATIENT_FOR_MERGE",
  SELECT_PATIENT_FOR_MERGE: "SELECT_PATIENT_FOR_MERGE",
  DESELECT_PATIENT_FOR_MERGE: "DESELECT_PATIENT_FOR_MERGE",
  READ_MORE_REPORT_CLICKED: "READ_MORE_REPORT_CLICKED"
};
var MIXPANEL_PAGES = {
  PATIENTS: "PATIENTS",
  VIEWER: "VIEWER",
  PATIENT_CHANNEL: "PATIENT_CHANNEL"
};
var MIXPANEL_OTHER_EVENTS = {
  PAGE_EXIT: "Page Exit"
};
export { MIXPANEL_EVENTS, MIXPANEL_PAGES, MIXPANEL_OTHER_EVENTS };