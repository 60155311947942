import React, { useContext, useEffect } from "react";
import Alert from ".";
import { getIsLoggedIn, getIsOnpremise, getIsPatientDeletionEnabled } from "../../utils";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import { get, isEmpty } from "lodash";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { PatientsContext } from "../Patients/PatientsContext";
import { LicenseContext } from "../LicenseManager/LicenseContext";
import { useTranslation } from "react-i18next";

function Warning() {
  const { wsData } = useContext(PatientsContext);
  const { stickyAlert, alertDetails } = useContext(LicenseContext);
  const isOnpremise = getIsOnpremise();
  const isPatientDeletionEnabled = getIsPatientDeletionEnabled();
  const isLoggedin = getIsLoggedIn();
  const { t } = useTranslation();
  const { authData, isDiskSpaceWarning, patientsFileDeletionStatus } = useStoreState(
    Store,
    (store) => ({
      user: store.user,
      authData: store.userInfo,
      hasLicenseExpiredForSelectedPatient: store.hasLicenseExpiredForSelectedPatient,
      isDiskSpaceWarning: store.isDiskSpaceWarning,
      patientsFileDeletionStatus: store.patientsFileDeletionStatus,
    })
  );

  useEffect(() => {
    if (wsData && wsData?.action == "license_activation") {
      Store.update((s) => {
        s.userInfo = {
          ...s.userInfo,
          trial_scans_remaining: wsData?.content?.trial_scans_remaining,
        };
      });
    }
  }, [wsData]);

  const diskUsage = get(authData, ["disk_usage"], {});
  const trial_scans_remaining = get(authData, ["trial_scans_remaining"], 0);

  const isDeletionStatus = (status) => ["in_process", "started"].includes(status);
  const isFilesDeletionOn =
    isDeletionStatus(diskUsage?.file_deletion) ||
    isDeletionStatus(patientsFileDeletionStatus);

  const onClickDiskAlert = () => {
    Store.update((s) => {
      s.isSettingsOpen = true;
      s.selectedSetting = "disk-management";
    });
  };

  const onClickLicenseAlert = () => {
    Store.update((s) => {
      s.isSettingsOpen = true;
      s.selectedSetting = "license";
    });
  };

  const diskAlertContent = () => {
    if (!isOnpremise || !isPatientDeletionEnabled) return null;

    if (isFilesDeletionOn && patientsFileDeletionStatus !== "completed") {
      return (
        <Alert
          className="bg-red-800"
          messageKey="disk_management.deletion_message"
          showProgress={true}
        />
      );
    }

    if (diskUsage?.used_percent > 99) {
      return (
        <Alert
          className="bg-red-600"
          icon={faTriangleExclamation}
          messageKey="disk_management.popup_message_critical"
        />
      );
    }

    if (isDiskSpaceWarning) {
      return (
        <Alert
          className="bg-yellow-400 text-gray-900"
          icon={faTriangleExclamation}
          onClick={onClickDiskAlert}
          messageKey="disk_management.popup_message_warning"
          dynamicMessagePart={{
            usedPercent: diskUsage?.used_percent,
            totalDisk: diskUsage?.total,
          }}
        />
      );
    }

    return null;
  };

  const licenseExpiredContent = () => {
    if (
      isLoggedin &&
      isOnpremise &&
      !isEmpty(alertDetails) &&
      !alertDetails?.remainingPercentage
    ) {
      return (
        <Alert
          className="bg-red-600 cursor-pointer"
          icon={faTriangleExclamation}
          messageKey="license_expire.popup_message"
          onClick={onClickLicenseAlert}
        />
      );
    }
    return null;
  };
  const licenseAlertContent = () => {
    if (!isLoggedin || !isOnpremise || (isOnpremise && isEmpty(stickyAlert))) return null;

    const { license_type, total_days_remaining, total_scans_remaining } = stickyAlert;
    const remaining =
      license_type === "TIME" ? `${total_days_remaining}` : `${total_scans_remaining}`;

    let message = t("license_manager.license_expire_after").replace("[1]", remaining);
    if (license_type === "TIME") {
      message = t("license_manager.license_expire_in").replace("[1]", remaining);
    }

    return (
      <Alert
        className="bg-yellow-400 text-gray-800 cursor-pointer"
        icon={faTriangleExclamation}
        messageKey={message}
        onClick={onClickLicenseAlert}
      />
    );
  };

  const trialLicenseAlertContent = () => {
    const isTrialActive = !!trial_scans_remaining && trial_scans_remaining > 0;

    if (!isTrialActive || !isLoggedin) {
      return null;
    }

    const message = t("license_manager.trial_license_remaining").replace(
      "[1]",
      trial_scans_remaining
    );
    return (
      <Alert
        className="bg-yellow-800 cursor-pointer"
        messageKey={message}
        onClick={onClickLicenseAlert}
      />
    );
  };

  return (
    diskAlertContent() ||
    licenseExpiredContent() ||
    licenseAlertContent() ||
    trialLicenseAlertContent()
  );
}

export default Warning;
