import classNames from "classnames";
import useMedia, { MediaSizes } from "../../hooks/useMedia";
import { useTranslation } from "react-i18next";

const Tag = ({ label, onClick, isSelected, disabled = false, mapping }) => {
  const isMobile = useMedia([MediaSizes.MOBILE], [true], false);
  const { t } = useTranslation();

  return (
    <button
      disabled={disabled}
      className={classNames(
        "rounded-full border border-gray-600 w-max px-5 cursor-pointer sm:text-[12px] sm:py-2",
        {
          "!bg-teal-400 !text-gray-100 !border-teal-400": isSelected,
          "hover:border-teal-400 hover:bg-teal-400 hover:text-gray-100":
            !isMobile && !disabled,
          "opacity-50 !cursor-not-allowed": disabled,
        }
      )}
      onClick={onClick}
    >
      {mapping ? t(`mapping.${label}`) : label}
    </button>
  );
};

export default Tag;
