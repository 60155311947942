import servicesUtils from "../../../utils/servicesUtils";
var getCloudSyncStatus = function getCloudSyncStatus() {
  return new Promise(function (resolve, reject) {
    servicesUtils.getService("/qsync/last_synced/").then(function (data) {
      resolve(data);
    })["catch"](function (err) {
      reject(err);
    });
  });
};
export default getCloudSyncStatus;