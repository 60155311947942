import React from "react";
import { useTranslation } from "react-i18next";
import DateRangeInput from "../FormComponents/DateRangeInput";
import Tag from "../SearchFilters/Tag";
import Toast from "../Toast";
import SelectInput from "../FormComponents/SelectInput";

const Filters = ({ filters, setFilters, isUsecaseDisabled }) => {
  const { t } = useTranslation();

  const usecaseOptions = [
    {
      label: t("mapping.tb"),
      value: "tb",
    },
    {
      label: t("mapping.tb_lung_cancer"),
      value: "tb_lung_cancer",
    },
    {
      label: t("mapping.lung_cancer"),
      value: "lung_cancer",
    },
    {
      label: t("mapping.stroke"),
      value: "stroke",
    },
  ];

  const handleDateRangeChange = (value) => {
    if (value[0] > new Date() || value[1] > new Date()) {
      Toast.error(t("toast.invalid_date"));
      return;
    }
    setFilters({
      ...filters,
      dateRange: value,
    });
  };

  const setDate = (daysFrom, daysTo) => {
    const d = new Date();
    const e = new Date();
    d.setDate(d.getDate() + daysFrom);
    e.setDate(e.getDate() + daysTo);
    setFilters({
      ...filters,
      dateRange: [d, e],
    });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <div className="text-[12px] font-semibold uppercase">
          {t("export_data_modal.usecase")}
        </div>
        <SelectInput
          value={filters.usecase}
          options={usecaseOptions}
          disabled={isUsecaseDisabled}
          placeholder={t("export_data_modal.usecase_placeholder")}
          onChange={(val) =>
            setFilters({
              ...filters,
              usecase: val,
            })
          }
          className="w-full my-2"
        />
      </div>
      <div className="space-y-3">
        <div className="text-[12px] font-semibold">{t("date_key")}</div>
        <DateRangeInput
          tooltip={"Scan upload date"}
          placeholder={t("date_filter_placeholder")}
          value={filters.dateRange}
          onChange={handleDateRangeChange}
        />
        <div className="flex flex-wrap gap-3">
          <Tag
            label={t("export_data_modal.last_1_week")}
            onClick={() => setDate(-7, 0)}
          />
          <Tag
            label={t("export_data_modal.last_1_month")}
            onClick={() => setDate(-30, 0)}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
